import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default () => (
  <Layout>
    <SEO title="404" description="" keywords="" />
    <div className="container">
      <h2>Page doesn't exist</h2>
    </div>
  </Layout>
);
